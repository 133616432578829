class FirestoreClient {
  constructor(firestore) {
    this.firestore = firestore;
  }

  buildQuery(options = {}) {
    const q = {
      limit: 10,
      orderBy: 'created_at',
      orderDir: 'desc',
      ...options,
    };
    let query = this.firestore.collection('messages')
      .where('spam', '==', 0)
      .orderBy(q.orderBy, q.orderDir)
      .limit(q.limit);
    if (q.startAfter) {
      query = query.startAfter(q.startAfter);
    }
    if (q.endBefore) {
      query = query.endBefore(q.endBefore);
    }
    return query;
  }

  async getQuery() {
    return this.buildQuery();
  }

  async list(options) {
    // console.log('firebase query', options);
    const snapshot = await this.buildQuery(options).get();
    return snapshot.docs.map(
      doc => ({ ...(doc.data()), id: doc.id }),
    );
  }

  async update(id, data) {
    // console.log(`firebase update messages/${id}`, data);
    return this.firestore.doc(`messages/${id}`).update(data);
  }

  async delete(id) {
    // console.log(`firebase delete messages/${id}`);
    return this.firestore.doc(`messages/${id}`).delete();
  }
}

export default FirestoreClient;
