import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import DataContext from './components/data';
import App from './components/app';
import * as serviceWorker from './serviceWorker';
import './index.scss';

const rootNode = document.getElementById('root');
const preload = rootNode && rootNode.dataset.preload
  ? JSON.parse(decodeURIComponent(rootNode.dataset.preload))
  : undefined;
delete rootNode.dataset.preload;

ReactDOM.render(
  (
    <DataContext.Provider preload={preload}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </DataContext.Provider>
  ),
  rootNode,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
