import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const formatDate = (time, long = true) => (new Date(time)).toLocaleString('en-US', {
  hour12: false,
  weekday: long ? 'long' : 'short',
  month: long ? 'long' : 'short',
  day: 'numeric',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
});

const MessageDetails = ({ message }) => (
  <section className="message-details">
    <blockquote>{message.content}</blockquote>
    <div className="attribution">
      <p className="name">
        &mdash;&nbsp;
        {message.name}
      </p>
      <p>
        <span className="date-short">{formatDate(message.created_at, false)}</span>
        <span className="date-long">{formatDate(message.created_at, true)}</span>
        <span className="origin">{` via ${message.origin}`}</span>
      </p>
    </div>
  </section>
);

const {
  string, number, shape, oneOf,
} = PropTypes;

MessageDetails.propTypes = {
  message: shape({
    id: string,
    name: string,
    content: string,
    origin: oneOf(['www', 'mobile', 'shell']),
    likes: number,
    spam: number,
    created_at: number,
    // ip: number,
    // user_agent: string,
  }),
};

MessageDetails.defaultProps = {
  message: undefined,
};

export default MessageDetails;
