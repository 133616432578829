/* eslint-disable react/no-unused-state */

import React from 'react';
import PropTypes from 'prop-types';
import FirebaseClient from './firebase';
import ApiClient from './api';

const ITEMS_PER_PAGE = 20;

const DataContext = React.createContext();

class Data extends React.Component {
  constructor(props) {
    super(props);
    this.initFirebase();
    this.api = new ApiClient();
    this.state = {
      message: (props.preload && props.preload.message) || {},
      messages: props.preload && Array.isArray(props.preload.messages)
        ? props.preload.messages
        : [],
      hasMore: true,
      currentUser: null,
      isLoggedIn: false,
      actions: {
        load: () => this.load(),
        update: () => this.update(),
        get: id => this.getMessage(id),
        like: id => this.like(id),
        unlike: id => this.unlike(id),
        delete: id => this.delete(id),
        report: id => this.report(id),
        post: (message, name) => this.post(message, name),
        login: (email, password) => this.login(email, password),
        logout: () => this.logout(),
        getPreloaded: id => this.getPreloaded(id),
      },
    };
  }

  async getMessage(id, forceRefetch = false) {
    const { message } = this.state;
    if (message[id] && !forceRefetch) {
      return message[id];
    }
    const { items } = await this.api.fetchMessage(id) || {};
    const item = items && items.length ? items[0] : null;
    if (item) {
      this.setState({ message: { ...message, [id]: item } });
    }
    return item;
  }

  getPreloaded(id) {
    const { message } = this.state;
    return message[id];
  }

  initFirebase() {
    if (typeof window === 'undefined' || !window.IS_BROWSER) {
      return;
    }
    this.firebase = new FirebaseClient();
    this.firebase.onAuthStateChange((user) => {
      this.setState({
        currentUser: user,
        isLoggedIn: !!user,
      });
      if (user) {
        user.getIdToken(true)
          .then(idToken => this.api.setAuthToken(idToken))
          .catch(() => this.api.setAuthToken(null));
      } else {
        this.api.setAuthToken(null);
      }
    });
  }

  async update() {
    const { messages } = this.state;
    if (!messages.length) {
      return messages;
    }
    const data = await this.api.fetchUpdates(messages[0].created_at);
    // @TODO: Error handling
    const newMessages = data.items.concat(messages);
    this.setState({ messages: newMessages });
    return newMessages;
  }

  async post(message, name) {
    const newMessage = await this.api.createMessage(message, name);
    await this.update();
    return newMessage;
  }

  removeMessage(id) {
    const { messages, message } = this.state;
    const index = messages.findIndex(item => item.id === id);
    if (index > -1) {
      messages.splice(index, 1);
      delete message[id];
      this.setState({ messages, message });
    }
  }

  replaceMessage(m) {
    const { messages, message } = this.state;
    const index = messages.findIndex(item => item.id === m.id);
    if (index > -1) {
      messages.splice(index, 1, m);
      this.setState({ messages, message: { ...message, [m.id]: m } });
    }
  }

  async like(id) {
    const { items } = await this.api.likeMessage(id);
    const message = items && items.length ? items[0] : null;
    this.replaceMessage(message);
    return message;
  }

  async unlike(id) {
    const { items } = await this.api.unlikeMessage(id);
    const message = items && items.length ? items[0] : null;
    this.replaceMessage(message);
    return message;
  }

  async load() {
    const { messages, hasMore } = this.state;
    if (!hasMore) {
      return messages;
    }
    const offset = messages.length ? messages[messages.length - 1].created_at : undefined;
    const data = await this.api.fetchMessages(ITEMS_PER_PAGE, offset);
    // @TODO: Error handling
    const newMessages = messages.concat(data.items);
    this.setState({
      hasMore: data.items.length === ITEMS_PER_PAGE,
      messages: newMessages,
    });
    return newMessages;
  }

  async delete(id) {
    await this.firebase.delete(id);
    this.removeMessage(id);
  }

  async report(id) {
    await this.firebase.update(id, { spam: 2 });
    this.removeMessage(id);
  }

  async login(email, password) {
    return this.firebase.login(
      email,
      password,
    );
  }

  async logout() {
    return this.firebase.logout();
  }

  reset() {
    this.setState({
      messages: [],
      hasMore: true,
    });
  }

  render() {
    const { children } = this.props;
    return (
      <DataContext.Provider value={this.state}>
        {children}
      </DataContext.Provider>
    );
  }
}

Data.propTypes = {
  children: PropTypes.node.isRequired,
  preload: PropTypes.object,
};

Data.defaultProps = {
  preload: {},
};

export default {
  Consumer: DataContext.Consumer,
  Provider: Data,
};

// eslint-disable-next-line react/no-multi-comp
export const withData = Component => React.forwardRef((props, ref) => (
  <DataContext.Consumer>
    {context => <Component context={context} ref={ref} {...props} />}
  </DataContext.Consumer>
));
