import React from 'react';
import PropTypes from 'prop-types';
import { formatFullDateString, getRelativeTime } from '../../util/date';

const UPDATE_INTERVAL = 5000;

class RelativeTime extends React.Component {
  constructor(props) {
    super(props);
    this.reqestAnimation = null;
    this.state = {
      relativeTime: getRelativeTime(props.date, new Date()),
      lastUpdated: 0,
    };
  }

  componentDidMount() {
    this.setState({ lastUpdated: 0 }, () => this.update(UPDATE_INTERVAL));
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.reqestAnimation);
  }

  update(time) {
    const { lastUpdated } = this.state;
    if (time - lastUpdated >= UPDATE_INTERVAL) {
      const { date } = this.props;
      this.setState({
        relativeTime: getRelativeTime(date, new Date()),
        lastUpdated: time,
      });
    }
    this.reqestAnimation = window.requestAnimationFrame(t => this.update(t));
  }

  render() {
    const { relativeTime } = this.state;
    const { date } = this.props;
    return <span title={formatFullDateString(date)}>{relativeTime}</span>;
  }
}

RelativeTime.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
};

export default RelativeTime;
