import React from 'react';
import './index.scss';

const Loader = () => (
  <div className="ol-loader">
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Loader;
