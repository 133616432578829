import React from 'react';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import InfiniteScroll from 'react-infinite-scroller';
import { withData } from '../../components/data';
import MessageList from '../../components/message-list';
import MessageListPlaceholder from '../../components/message-list-placeholder';
import MessagePost from '../../components/message-post';
import Loader from '../../components/loader';

class PageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reset: false,
      initialLoad: true,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0 });
    }
    const { context } = this.props;
    const { actions, messages } = context;
    if (messages && messages.length) {
      this.setState({ initialLoad: false });
    } else {
      actions.load().then(() => this.setState({ initialLoad: false }));
    }
    this.updateInterval = setInterval(() => context.actions.update(), 1000 * 60);
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval);
  }

  reset() {
    const { context } = this.props;
    context.actions.reset();
    requestAnimationFrame(() => this.setState({ reset: true }));
  }

  clearReset() {
    requestAnimationFrame(() => this.setState({ reset: false }));
  }

  render() {
    const { context } = this.props;
    const { initialLoad, reset } = this.state;
    const { hasMore, messages, actions } = context;
    if (reset) {
      this.clearReset();
      return null;
    }
    if (!context) return null;
    return (
      <>
        <MetaTags>
          <title>the dwyfl.org oneliner</title>
          <meta property="og:title" content="the dwyfl.org oneliner" />
          <meta property="og:description" content="A messageboard in association with the dwyfl cooperative." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`${process.env.PUBLIC_URL || ''}`} />
        </MetaTags>
        <MessagePost disabled={initialLoad} />
        <InfiniteScroll
          initialLoad={false}
          loadMore={actions.load}
          hasMore={hasMore}
          loader={initialLoad ? <MessageListPlaceholder key={0} /> : <Loader key={0} />}
        >
          <MessageList items={messages} key={1} />
        </InfiniteScroll>
      </>
    );
  }
}

const {
  bool, shape, func, arrayOf, object,
} = PropTypes;

PageList.propTypes = {
  context: shape({
    messages: arrayOf(object),
    hasMore: bool,
    actions: shape({
      reset: func,
      load: func,
      update: func,
    }),
  }).isRequired,
};

export default withData(PageList);
