const storage = typeof localStorage !== 'undefined'
  ? localStorage
  : { getItem: () => null, setItem: () => true };

class Storage {
  static get(key) {
    const rawValue = storage.getItem(key);
    if (rawValue === null || rawValue === '') {
      return null;
    }
    try {
      return JSON.parse(rawValue);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Unable to get localStorage item "%s": "%s"', key, rawValue);
    }
    return null;
  }

  static set(key, value) {
    try {
      const jsonValue = JSON.stringify(value);
      storage.setItem(key, jsonValue);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Unable to set localStorage item "%s": "%s"', key, value);
      return false;
    }
    return true;
  }
}

export default Storage;
