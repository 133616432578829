import PageMessageList from './list';
import PageMessageDetails from './details';
import PageLogin from './login';
import PageNotFound from './notfound';

const DEFAULT_OPTIONS = {
  title: 'the dwyfl.org oneliner',
  showHeader: true,
  showFooter: false,
  analytics: true,
};

const ROUTES = {
  start: {
    path: '/',
    component: PageMessageList,
  },
  login: {
    path: '/login',
    component: PageLogin,
    options: {
      analytics: false,
    },
  },
  details: {
    path: '/message/:id',
    component: PageMessageDetails,
    options: {
      title: undefined,
      showHeader: false,
    },
  },
  notfound: {
    path: '',
    component: PageNotFound,
    options: {
      showHeader: false,
      showFooter: false,
    },
  },
};

Object.keys(ROUTES).forEach((key) => {
  ROUTES[key].options = Object.assign(
    {},
    DEFAULT_OPTIONS,
    (ROUTES[key].options || {}),
  );
});

export default ROUTES;
