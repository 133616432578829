import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withData } from '../../components/data';
import './index.scss';

class PageLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: false,
      disabled: false,
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0 });
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  logout(e) {
    e.preventDefault();
    const { history, context } = this.props;
    this.setState({ disabled: true, error: false }, () => {
      context.actions.logout()
        .then(() => {
          this.setState({ disabled: false });
          history.push('/');
        })
        .catch((error) => {
          this.setState({ disabled: false, error: error.toString() });
        });
    });
  }

  login(e) {
    e.preventDefault();
    const { history, context } = this.props;
    const { email, password } = this.state;
    this.setState({ disabled: true, error: false }, () => {
      context.actions.login(email, password)
        .then(() => {
          this.setState({ disabled: false, email: '', password: '' });
          history.push('/');
        })
        .catch((error) => {
          this.setState({ disabled: false, error: error.toString() });
        });
    });
  }

  renderLoginForm() {
    const {
      email, password, disabled, error,
    } = this.state;
    return (
      <React.Fragment>
        {error && <p>{error}</p>}
        <form method="post" onSubmit={e => this.login(e)} className="ol-login">
          <label htmlFor="ol-login-email">
            <input required disabled={disabled} placeholder="Email" type="text" id="ol-login-email" name="email" value={email} onChange={e => this.onChange(e)} />
          </label>
          <label htmlFor="ol-login-password">
            <input required disabled={disabled} placeholder="Password" type="password" id="ol-login-password" name="password" value={password} onChange={e => this.onChange(e)} />
          </label>
          <div>
            <button type="submit" disabled={disabled}>Login</button>
          </div>
        </form>
      </React.Fragment>
    );
  }

  renderLogoutForm() {
    const { disabled, error } = this.state;
    return (
      <React.Fragment>
        {error && <p>{error}</p>}
        <form method="post" onSubmit={e => this.logout(e)} className="ol-login">
          <div>
            <button type="submit" disabled={disabled}>Logout</button>
          </div>
        </form>
      </React.Fragment>
    );
  }

  render() {
    const { context } = this.props;
    return context.isLoggedIn
      ? this.renderLogoutForm()
      : this.renderLoginForm();
  }
}

const {
  object, shape, func, bool,
} = PropTypes;

PageLogin.propTypes = {
  history: object.isRequired, // eslint-disable-line react/forbid-prop-types
  context: shape({
    isLoggedIn: bool,
    actions: shape({
      login: func,
    }),
  }).isRequired,
};

export default withRouter(withData(PageLogin));
