import React from 'react';
import './index.scss';

const MessagePlaceholder = () => (
  <section className="message-details placeholder">
    <blockquote>
      <div className="message-text animated" />
      <div className="message-text animated" />
      <div className="message-text animated" />
    </blockquote>
    <div className="attribution">
      <p><span className="message-text animated" /></p>
      <p><span className="message-text animated" /></p>
    </div>
  </section>
);

export default MessagePlaceholder;
