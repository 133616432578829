import React from 'react';
import PropTypes from 'prop-types';
import { withData } from '../data';
import Settings from '../../util/settings';
import './index.scss';
import ActionIcon from '../icon/action-icon';

const getStoredName = () => Settings.getItem('ol-name') || '';
const setStoredName = name => Settings.setItem('ol-name', name);

class MessagePost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      error: false,
      disabled: false,
      name: getStoredName(),
      message: '',
    };
  }

  onSubmit(e) {
    e.preventDefault();
    const { context } = this.props;
    const { message, name } = this.state;
    setStoredName(name);
    this.setState({ disabled: true },
      () => context.actions.post(message, name)
        .then(() => this.setState({ disabled: false, hidden: true, message: '' }))
        .catch(() => this.setState({ disabled: false, error: true })));
  }

  onChange(e) {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value,
    });
  }

  render() {
    const {
      name, message, hidden, error,
    } = this.state;
    const disabled = this.props.disabled || this.state.disabled; // eslint-disable-line react/destructuring-assignment
    return (
      <section className={`message-post${disabled ? ' disabled' : ''}${hidden ? ' hidden' : ''}`}>
        {error && <p className="error">There was an error posting. Try again later.</p>}
        <form onSubmit={e => this.onSubmit(e)}>
          <div className="message-post-form-wrapper">
            <input
              type="text"
              name="message"
              placeholder={disabled ? '' : 'Enter your message…'}
              aria-label="Enter your message…"
              onChange={e => this.setState({ message: e.currentTarget.value })}
              value={message}
              required
              pattern=".*[^\s].*"
              maxLength="250"
              autoComplete="off"
              disabled={disabled}
              onFocus={() => this.setState({ hidden: false })}
            />
            <button
              type="submit"
              name="submit"
              aria-label="Post message"
              id="input-submit"
              disabled={disabled}
              onFocus={() => this.setState({ hidden: false })}
            >
              <ActionIcon type="send" alt="Post message" />
            </button>
          </div>
          <input
            type="text"
            name="name"
            placeholder="Name"
            aria-label="Name"
            onChange={e => this.setState({ name: e.currentTarget.value })}
            value={name}
            required
            pattern=".*[^\s].*"
            maxLength="16"
            disabled={disabled}
          />
        </form>
      </section>
    );
  }
}

MessagePost.propTypes = {
  disabled: PropTypes.bool,
  context: PropTypes.shape({
    actions: PropTypes.shape({
      post: PropTypes.func,
    }),
  }).isRequired,
};

MessagePost.defaultProps = {
  disabled: false,
};

export default withData(MessagePost);
