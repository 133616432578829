import React from 'react';
import PropTypes from 'prop-types';
import ActionIcon from '../icon/action-icon';
import './index.scss';

const MessageListPlaceholderItem = ({ opacity }) => (
  <li style={{ opacity }}>
    <div className="message placeholder">
      <div className="message-origin">
        <ActionIcon type="heart" />
      </div>
      <div className="message-content">
        <div className="message-text animated" />
        <div className="message-info animated" />
      </div>
    </div>
  </li>
);

MessageListPlaceholderItem.propTypes = {
  opacity: PropTypes.number,
};

MessageListPlaceholderItem.defaultProps = {
  opacity: 1,
};

const MessageListPlaceholder = ({ numberOfItems }) => {
  const items = [];
  for (let i = 0; i < numberOfItems; i += 1) {
    items.push(
      <MessageListPlaceholderItem
        key={`placeholder-${i}`}
        opacity={(numberOfItems - i) / numberOfItems}
      />,
    );
  }
  return (<ul className="message-list">{items}</ul>);
};

MessageListPlaceholder.propTypes = {
  numberOfItems: PropTypes.number,
};

MessageListPlaceholder.defaultProps = {
  numberOfItems: 5,
};

export default MessageListPlaceholder;
