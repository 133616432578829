import Storage from './storage';

const SETTINGS_KEY = 'ol_conf';
const SETTINGS_DEFAULTS = {};

class Settings {
  static get() {
    const settings = Storage.get(SETTINGS_KEY);
    return settings && typeof settings === 'object' ? settings : SETTINGS_DEFAULTS;
  }

  static set(obj) {
    const settings = Settings.get();
    Object.keys(obj).forEach((key) => { settings[key] = obj[key]; });
    return Storage.set(SETTINGS_KEY, settings);
  }

  static getItem(key) {
    const settings = Settings.get();
    return settings[key];
  }

  static setItem(key, value) {
    return Settings.set({ [key]: value });
  }
}

export default Settings;
