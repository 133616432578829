import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import routes from '../../routes';
import Header from '../header';
import Footer from '../footer';
import './index.scss';

const App = () => (
  <>
    <MetaTags>
      <title>the dwyfl.org oneliner</title>
      <meta property="description" content="A messageboard in association with the dwyfl cooperative." />
      <meta property="og:site_name" content="the dwyfl.org oneliner" />
      <meta property="og:title" content="the dwyfl.org oneliner" />
      <meta property="og:description" content="A messageboard in association with the dwyfl cooperative." />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${process.env.PUBLIC_URL || ''}`} />
      <meta property="og:image" content={`${process.env.PUBLIC_URL || ''}/apple-touch-icon.png`} />
    </MetaTags>
    <Switch>
      {Object.keys(routes).map(key => (
        <Route
          exact
          key={key}
          path={routes[key].path}
          render={(props) => {
            if (routes[key].options.title && typeof document !== 'undefined') {
              document.title = routes[key].options.title;
            }
            if (routes[key].options.analytics && typeof window !== 'undefined' && window.ga) {
              window.ga('send', 'pageview');
            }
            return (
              <div className={`ol-main ol-${key}`}>
                {routes[key].options.showHeader && <Header />}
                <section className="ol-content">
                  {React.createElement(routes[key].component, props)}
                </section>
                {routes[key].options.showFooter && <Footer />}
              </div>
            );
          }
          }
        />
      ))}
    </Switch>
  </>
);

export default App;
