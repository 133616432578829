import Settings from './settings';

const LIKES_SETTINGS_KEY = 'likes';

class Likes {
  static getLikes() {
    const likes = Settings.getItem(LIKES_SETTINGS_KEY);
    return Array.isArray(likes) ? likes : [];
  }

  static setLikes(likes) {
    return Settings.setItem(
      LIKES_SETTINGS_KEY,
      Array.isArray(likes) ? likes : [],
    );
  }

  static isLiked(id) {
    return Likes.getLikes().includes(id);
  }

  static like(id) {
    const likes = Likes.getLikes();
    likes.push(id);
    Likes.setLikes(likes);
  }

  static unlike(id) {
    const likes = Likes.getLikes();
    const index = likes.indexOf(id);
    if (index > -1) {
      likes.splice(index, 1);
      Likes.setLikes(likes);
    }
  }
}

export default Likes;
