import React from 'react';
import PropTypes from 'prop-types';
import MessageListItem from './components/item';
import './index.scss';

const MessageList = ({ items, loading }) => (items
  ? (
    <ul className={`message-list ${loading ? ' loading' : ''}`}>
      {items.map(item => (
        <li key={item.id}>
          <MessageListItem message={item} />
        </li>
      ))}
    </ul>
  )
  : null
);

MessageList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
};

MessageList.defaultProps = {
  items: undefined,
  loading: false,
};

export default MessageList;
