export default {
  firebase: {
    apiKey: 'AIzaSyDIrJu17RK-1lx1gVIz-YrxTpb8uJmhST0',
    authDomain: 'dwyflorg.firebaseapp.com',
    databaseURL: 'https://dwyflorg.firebaseio.com',
    projectId: 'dwyflorg',
    storageBucket: 'dwyflorg.appspot.com',
    messagingSenderId: '35832889400',
  },
};
