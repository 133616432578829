import React from 'react';
import PropTypes from 'prop-types';
import Icon from '.';

const OriginIcon = ({ type }) => (
  <Icon type={type} alt={`Posted via ${type}`} />
);

OriginIcon.propTypes = {
  type: PropTypes.oneOf(['www', 'mobile', 'shell']).isRequired,
};

export default OriginIcon;
