require('dotenv').config();
require('es6-promise').polyfill();
require('isomorphic-fetch');

// eslint-disable-next-line no-undef
const API_BASE_URL = IS_BROWSER ? '' : `http://localhost:${process.env.SERVER_PORT}`;

const getQueryParams = params => Object.keys(params).map(
  key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
).join('&');

class ApiClient {
  constructor() {
    this.authToken = null;
  }

  setAuthToken(value) {
    this.authToken = value;
  }

  getDefaultHeaders(method = 'get') {
    const headers = {};
    if (method === 'post') {
      headers['Content-Type'] = 'application/json';
    }
    if (this.authToken) {
      headers.Authorization = this.authToken;
    }
    return headers;
  }

  // eslint-disable-next-line class-methods-use-this
  call(path, options) {
    const reqOptions = {
      headers: this.getDefaultHeaders(options.method),
      ...options,
    };
    return fetch(`${API_BASE_URL}${path}`, reqOptions)
      .then(res => res.json())
      .catch(error => ({ error }))
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        return res.data;
      });
  }

  get(path, options = {}) {
    return this.call(path, {
      method: 'get',
      ...options,
    });
  }

  post(path, data, options = {}) {
    return this.call(path, {
      method: 'post',
      body: data ? JSON.stringify(data) : '',
      ...options,
    });
  }

  delete(path, options = {}) {
    return this.call(path, {
      method: 'delete',
      ...options,
    });
  }

  fetchMessages(limit = 20, offset) {
    const query = { limit };
    if (offset) {
      query.offset = offset;
    }
    return this.get(`/api/messages?${getQueryParams(query)}`);
  }

  fetchMessage(id) {
    return this.get(`/api/messages/${id}`);
  }

  createMessage(message, name) {
    return this.post('/api/messages', { message, name });
  }

  deleteMessage(id) {
    return this.delete(`/api/messages/${id}`);
  }

  likeMessage(id) {
    return this.post(`/api/messages/${id}/like`);
  }

  unlikeMessage(id) {
    return this.delete(`/api/messages/${id}/like`);
  }

  reportMessage(id) {
    return this.post(`/api/messages/${id}/report`);
  }

  fetchUpdates(time) {
    return this.get(`/api/messages/updates/${time}`);
  }
}

export default ApiClient;
