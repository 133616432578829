import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Linkify from 'react-linkify';
import { withData } from '../../../data';
import Likes from '../../../../util/likes';
import RelativeTime from '../../../relative-time';
import OriginIcon from '../../../icon/origin-icon';
import ActionIcon from '../../../icon/action-icon';

import './index.scss';

class MessageListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      liked: Likes.isLiked(props.message.id),
      disabled: false,
    };
  }

  likeMessage(id) {
    const { context } = this.props;
    const { liked, disabled } = this.state;
    const { like, unlike } = context.actions;
    if (disabled) {
      return;
    }
    this.setState({ liked: !liked, disabled: true });
    if (liked) {
      Likes.unlike(id);
      unlike(id).then(() => this.setState({ disabled: false }));
    } else {
      Likes.like(id);
      like(id).then(() => this.setState({ disabled: false }));
    }
  }

  reportMessage(id) {
    const { context } = this.props;
    return context.actions.report(id);
  }

  deleteMessage(id) {
    const { context } = this.props;
    return context.actions.delete(id);
  }

  render() {
    const { context, message } = this.props;
    const { liked } = this.state;
    return (
      <div className={`message${liked ? ' message-is-liked' : ''}`}>
        <div className="message-origin">
          <OriginIcon type={message.origin} />
        </div>
        <div className="message-content">
          <div className="message-text">
            <span className="message-name">{message.name}</span>
            <span className="message-content">
              <Linkify properties={{
                target: '_blank',
                rel: 'noopener noreferrer',
              }}
              >
                {message.content}
              </Linkify>
            </span>
          </div>
          <div className="message-info">
            <span className="message-date">
              <Link to={`/message/${message.id}`}>
                <RelativeTime date={new Date(message.created_at)} />
              </Link>
            </span>
            <span className="separator" />
            <span className="message-like">
              <button type="button" onClick={() => this.likeMessage(message.id)}>
                {liked ? 'Unlike' : 'Like'}
              </button>
            </span>
            {message.likes > 0 && (
              <span className="message-like-count">
                <span className="separator" />
                <button type="button" onClick={() => this.likeMessage(message.id)}>
                  <ActionIcon type="heart" />
                  {message.likes}
                </button>
              </span>
            )}
          </div>
        </div>
        {context.isLoggedIn && (
          <div className="message-actions">
            <button type="button" onClick={() => this.reportMessage(message.id)}>
              <ActionIcon type="spam" />
              <span className="message-action-label">Report</span>
            </button>
            <button type="button" onClick={() => this.deleteMessage(message.id)}>
              <ActionIcon type="delete" />
              <span className="message-action-label">Delete</span>
            </button>
          </div>
        )}
      </div>
    );
  }
}

const {
  shape, string, func, bool,
} = PropTypes;

MessageListItem.propTypes = {
  message: shape({
    id: string,
    content: string,
    name: string,
  }).isRequired,
  context: shape({
    isLoggedIn: bool,
    actions: shape({
      delete: func,
      report: func,
      unlike: func,
      like: func,
    }),
  }).isRequired,
};

export default withData(MessageListItem);
