import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import FirestoreClient from './firestore';
import config from '../../config';

const firebaseApp = firebase.initializeApp(config.firebase, 'clientApp');

class FirebaseClient {
  constructor() {
    this.auth = firebaseApp.auth();
    this.firestore = firebaseApp.firestore();
    this.firestore.settings({ timestampsInSnapshots: true });
    this.firestoreClient = new FirestoreClient(this.firestore);
  }

  getCurrentUser() {
    return this.auth.currentUser;
  }

  onAuthStateChange(func) {
    return this.auth.onAuthStateChanged(func);
  }

  async login(email, password) {
    return this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(
      () => this.auth.signInWithEmailAndPassword(email, password),
    );
  }

  async logout() {
    return this.auth.signOut();
  }

  async list(options) {
    return this.firestoreClient.list(options);
  }

  async update(id, data) {
    return this.firestoreClient.update(id, data);
  }

  async delete(id) {
    return this.firestoreClient.delete(id);
  }
}

export default FirebaseClient;
