import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './index.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  renderSubHeader() {
    const { layout } = this.props;
    const { open } = this.state;
    if (layout === 'small') {
      return null;
    }
    if (open) {
      return (
        <div className="ol-subheader">
          <p>
            {'In association with the '}
            <a href="http://dwyfl.org/">dwyfl.org</a>
            {' cooperative.'}
          </p>
          <p>
            {'Artwork from '}
            <a href="https://thenounproject.com/">The&nbsp;Noun&nbsp;Project</a>
            {'.'}
          </p>
        </div>
      );
    }
    return (
      <button type="button" className="ol-header-more" onClick={() => this.setState({ open: true })}>
        <h2>In association with the dwyfl cooperative</h2>
      </button>
    );
  }

  render() {
    const { layout } = this.props;
    return (
      <header className={`ol-header ol-header-${layout}`}>
        <h1>
          <Link to="/">
            <img src={`${process.env.PUBLIC_URL || ''}/assets/title.svg`} alt="the dwyfl.org oneliner" />
          </Link>
        </h1>
        {
          layout === 'default'
            ? <h2><img src={`${process.env.PUBLIC_URL || ''}/assets/subtitle.svg`} alt="In association with the dwyfl cooperative" /></h2>
            : null
        }
      </header>
    );
  }
}

Header.propTypes = {
  layout: PropTypes.oneOf(['default', 'small']),
};

Header.defaultProps = {
  layout: 'default',
};

export default Header;
