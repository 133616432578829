import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ type, alt }) => (
  <img
    className={`icon icon-${type}`}
    src={`${process.env.PUBLIC_URL || ''}/assets/${type}.svg`}
    alt={alt}
  />
);

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

Icon.defaultProps = {
  alt: '',
};

export default Icon;
