import React from 'react';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import { withData } from '../../components/data';
import MessageDetails from '../../components/message-details';
import MessagePlaceholder from '../../components/message-placeholder';
import Header from '../../components/header';

const getShortMessageSummary = message => `${message.name}: “${message.content}” @ the dwyfl oneliner`;
const getLongMessageSummary = message => `${message.name}: “${message.content}” via ${message.origin} on the dwyfl oneliner`;

class PageDetails extends React.Component {
  constructor(props) {
    super(props);
    const { match, context } = props;
    const message = context.actions.getPreloaded(match.params.id);
    this.state = { message };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0 });
    }
    const { message } = this.state;
    if (!message) {
      this.load();
    }
  }

  async load() {
    const { match, context } = this.props;
    this.setState({ message: await context.actions.get(match.params.id) });
  }

  render() {
    const { message } = this.state;
    return (
      <>
        {message ? (
          <MetaTags>
            <title>{getShortMessageSummary(message)}</title>
            <meta property="description" content={getLongMessageSummary(message)} />
            <meta property="og:title" content={`${message.name} @ oneliner.dwyfl.org`} />
            <meta property="og:description" content={message.content} />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={`${process.env.PUBLIC_URL || ''}/message/${message.id}`} />
          </MetaTags>
        ) : null}
        <Header layout="small" />
        {message ? <MessageDetails message={message} /> : <MessagePlaceholder />}
      </>
    );
  }
}

PageDetails.propTypes = {
  context: PropTypes.shape({
    actions: PropTypes.shape({
      get: PropTypes.func,
    }),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
};

export default withData(PageDetails);
