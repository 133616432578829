// Format date string

const pad = n => (n < 10 ? `0${n}` : n);

export const formatFullDateString = d => (d
  ? `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())} `
    + `${pad(d.getHours())}:${pad(d.getMinutes())}`
  : '');

export const formatShortLocaleDateString = (d, includeDay = true) => {
  const locale = 'en-US';
  const localeOptions = { hour12: false, hour: '2-digit', minute: '2-digit' };
  if (includeDay) {
    localeOptions.weekday = 'long';
  }
  return d ? d.toLocaleString(locale, localeOptions) : '';
};

export const formatLocaleDateString = (d, includeYear = true) => {
  const locale = 'en-US';
  const localeOptions = {
    hour12: false,
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  if (includeYear) {
    localeOptions.year = 'numeric';
  }
  return d ? d.toLocaleString(locale, localeOptions) : '';
};

export const formatLongLocaleDateString = (dateString, long = true) => (new Date(dateString)).toLocaleString('en-US', {
  hour12: false,
  weekday: long ? 'long' : 'short',
  month: long ? 'long' : 'short',
  day: 'numeric',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
});

// Human readable date

const MS_PER_MINUTE = 60 * 1000;
const MS_PER_HOUR = MS_PER_MINUTE * 60;
const MS_PER_DAY = MS_PER_HOUR * 24;

export const getRelativeTime = (previous, current) => {
  const difference = current - previous;
  if (difference < MS_PER_MINUTE) {
    return 'Less than a minute ago';
  }
  if (difference < MS_PER_HOUR) {
    const count = Math.round(difference / MS_PER_MINUTE);
    return `${count === 1 ? '1 minute' : `${count} minutes`} ago`;
  }
  if (difference < MS_PER_HOUR * 12) {
    const count = Math.round(difference / MS_PER_HOUR);
    return `${count === 1 ? '1 hour' : `${count} hours`} ago`;
  }
  if (difference < MS_PER_DAY * 3) {
    const wasYesterday = [1, 2, 3, 4, 5, 6, 0][previous.getDay()] === current.getDay();
    return wasYesterday
      ? `Yesterday at ${formatShortLocaleDateString(previous, false)}`
      : formatShortLocaleDateString(previous, true);
  }
  const wasLastYear = previous.getFullYear() !== current.getFullYear();
  return formatLocaleDateString(previous, wasLastYear && difference > MS_PER_DAY * 180);
};
