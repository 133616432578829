import React from 'react';

const PageNotFound = ({ staticContext }) => {
  if (staticContext) {
    staticContext.statusCode = 404;
  }
  return (<h1>404 NOT FOUND</h1>);
};

export default PageNotFound;
