import React from 'react';
import PropTypes from 'prop-types';
import Icon from '.';

const ActionIcon = ({ type }) => (
  <Icon type={type} alt="" />
);

ActionIcon.propTypes = {
  type: PropTypes.oneOf(['spam', 'delete', 'heart', 'send']).isRequired,
};

export default ActionIcon;
